<template>
  <div id="app" ref="app">
    <!--    <keep-alive>-->
    <router-view/>
    <!--    </keep-alive>-->
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style>
html,
body,
div,
span,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}


::-webkit-scrollbar {
  width: 0px !important;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
